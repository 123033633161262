import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import styles from "./LoginPage.module.css";
import { Button, IconButton, Link, TextField } from "@mui/material";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { Box } from "@mui/system";
import { login, sendPasswordResetEmail } from "../../api/Auth";
import { Auth } from "../../utils/Auth";
import { FoundationError } from "../../models/FoundationError";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading/Loading";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ToastContext } from "../../contexts/ToastContext";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";

const LoginPage: React.FC = () => {

  const { primaryColorLight, primaryTextColor, logoDark } = useContext(
    FoundationThemeContext
  );

  const navigate = useNavigate();

  const { refreshFacilities } = useContext(FacilitiesDataContext);

  const { showToast } = useContext(ToastContext);

  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPasswordButtonLoading, setForgotPasswordButtonLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(
    !(Auth.getInstance().getUserSettings() === undefined)
  );

  const [credentials, setCredentials] = useState({
    email: "",
    password: ""
  })

  const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value.trim()
    })
  }

  const handleRecoveryEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value as string;
    setPasswordRecoveryEmail(email);
  }

  const loginUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true);

    let errorMessage = ""
    const response = await login(credentials.email, credentials.password)
    if (!(response instanceof FoundationError)) {
      const error = await Auth.getInstance().signInWithToken(response.customToken)
      if (!(error instanceof FoundationError)) {
        const settings = response.getSettingsResponse
        Auth.getInstance().saveUserSettings(settings.userProfile)
        setLoggedIn(true);
        return;
      } else {
        errorMessage = error.message
      }
    } else {
      errorMessage = response.message
    }

    setIsLoading(false);
    showToast(errorMessage, "error");
  }

  const passwordReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setForgotPasswordButtonLoading(true)
    const response = await sendPasswordResetEmail(passwordRecoveryEmail);
    setForgotPasswordButtonLoading(false)
    if (response instanceof FoundationError) {
      showToast(response.getErrorMessage(), "error");
      return;
    }

    showToast("Recovery link has been sent to the registered email successfully", "success");
    setPasswordRecoveryEmail("")
    setShowForgetPassword(false);
  }

  useEffect(() => {
    if (isLoggedIn) {
      refreshFacilities();
      navigate("/");
    }
  }, [isLoggedIn, navigate, refreshFacilities]);

  return (
    <div
      className={styles.loginPage_root}
      style={{
        background: primaryColorLight,
      }}
    >
      <div className={styles.loginParentDiv}>
        <div className={styles.loginDiv}>
          <Box component="form" onSubmit={(e) => loginUser(e)} sx={{ p: "50px" }}>
            <h2 style={{ color: primaryTextColor }}>Sign In</h2>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleInputChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={handleInputChange}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className={styles.signInButton}
            >
              <Loading text={"Submit"} isLoading={isLoading} />
            </Button>
            <Link href="#" variant="body2" onClick={() => { setShowForgetPassword(true) }}>
              Forgot password?
            </Link>

          </Box>
        </div>
        <div className={styles.messageContainer}>
          <div className={styles.container}>
            <Box component="form" onSubmit={() => { }} sx={{ p: "50px", color: primaryTextColor }}>
              <img src={logoDark} alt="" />
              <h2>Login to continue</h2>
              <h5>Contact your organisation's admin if you are having trouble logging in</h5>
            </Box>
          </div>
        </div>
        <div className={`${styles.forgetPassword} ${showForgetPassword ? styles.forgetPasswordActive : ""}`}>
          <Box component="form" onSubmit={(e) => passwordReset(e)} sx={{ p: "50px" }} style={{}}>
            <div className={styles.closeButton}>
              <IconButton
                name="close"
                color="default"
                onClick={() => {
                  setShowForgetPassword(false);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <h2 style={{ color: primaryTextColor }}>Password Recovery</h2>

            <p style={{ fontSize: "1em" }}>{"A recovery link will be sent to the registered email id"}</p>

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={passwordRecoveryEmail}
              onChange={handleRecoveryEmailChange}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className={styles.signInButton}
            >
              <Loading text={"Submit"} isLoading={isForgotPasswordButtonLoading} />
            </Button>
          </Box>
        </div>
      </div>

    </div>
  );
}

export default LoginPage;
